import axios from 'axios';

// //api的访问url

// export const BASE_URL = 'http://www.rising56.com';
// //微信公众号的url,rooturl就是自己的
// export const BASE_ROOT_URL = 'http://m.rising56.com';
// export const BASE_TMS_URL = 'http://tms.rising56.com';
// export const WEIXIN_APPID = 'wx1cf96ed36909e4e1';
// export const APP_TITLE = '初升国际物流';

// export const BASE_URL = 'http://localhost:3898';
// export const BASE_ROOT_URL = 'http://localhost:8080';
// export const BASE_TMS_URL = 'http://localhost:4880';
// export const WEIXIN_APPID = 'wxdf0687546yt8766';
// export const APP_TITLE = '曲速软件';


// export const BASE_URL = 'http://gzua.zddexp.com';
// export const BASE_ROOT_URL = 'http://gzua.m.zddexp.com';
// export const BASE_TMS_URL = 'http://admin.gzua.zddexp.com';
// export const WEIXIN_APPID = 'wxdf06831cef06d1f6';
// export const APP_TITLE = '广州优澳';

// export const BASE_URL = 'http://oms.cbr-express.com';
// export const BASE_ROOT_URL = 'http://m.cbr-express.com';
// export const BASE_TMS_URL = 'http://tms.cbr-express.com';
// export const WEIXIN_APPID = 'wxb92e1fd55acdc857';
// export const APP_TITLE = '蓝鲸国际';


// export const BASE_URL = 'http://oms.yc-5566.com';
// export const BASE_ROOT_URL = 'http://oms.m.yc-5566.com';
// export const BASE_TMS_URL = 'http://tms.yc-5566.com';
// export const WEIXIN_APPID = 'wxe40375a957c18e80';
// export const APP_TITLE = '广州扬城';

// export const BASE_URL = 'http://qyoms.zddexp.com';
// export const BASE_ROOT_URL = 'http://qy.m.zddexp.com';
// export const BASE_TMS_URL = 'http://qytms.zddexp.com';
// export const WEIXIN_APPID = 'wxa3cf43817cca782e';
// export const APP_TITLE = '其颖国际';

export const BASE_URL = 'https://oms.wt6688.cn';
export const BASE_ROOT_URL = 'https://m.wt6688.cn';
export const BASE_TMS_URL = 'https://tms.wt6688.cn';
export const WEIXIN_APPID = 'wx4bb2e0755394f549';
export const APP_TITLE = '旺泰泰国专线物流';

// export const BASE_URL = 'http://ace.wto-logistics.com.cn';
// export const BASE_ROOT_URL = 'http://ace.m.wto-logistics.com.cn';
// export const BASE_TMS_URL = 'http://aceadmin.wto-logistics.com.cn';
// export const WEIXIN_APPID = 'wxf77a7ad857d857fc';
// export const APP_TITLE = '华雅国际';

export const API_Filer = axios.create({
  baseURL: `${BASE_URL}`,
  withCredentials: true,
  headers: {
    'Content-Type': 'multipart/form-data',
  },
});



//華雅wxc838cc41738792ba   wxa4bd8df67bd14b8e
const API = axios.create({
  baseURL: `${BASE_URL}`,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
});

API.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    if (typeof response.data === 'string' && response.data.match(/html/i)) {
      location.href = '/login';
    }
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);

export default API;
